.header_bg {
  background-image: url(../../../Image/bg1.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
#coin_img {
  padding-top: 20%;
  width: 140%;
  height: auto;
}
@media (max-width: 414px) {
  #coin_text {
    padding-top: 90px;
  }
  #coin_img {
    margin-top: 170px;
    margin-left: -10px;
    height: 23vh;
  }
  #coin_crypto_h1 {
    font-size: 24px;
  }
}
.bg1_img img {
  width: 100%;
  height: auto;
}

@media(min-width:415px) and (max-width:650px){
  #coin_img{
    margin-top: 75%;
  }
}

/* @media(min-width:416px) and (max-width:480px){
  #responsive_header_text{
    margin-top: 120px;
  }
} */