/* .product_img{
    background-image: url(../../../Image/Png.png);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
} */
.mango_img img {
  width: 100%;
  height: auto;
  margin-top: 50%;
}
.mango_text h1 {
  margin-top: 35%;
  font-family: "Times New Roman", Times, serif;
  border-bottom: 2px solid black;
}
.mango_text1 {
  background-color: rgb(255, 138, 0);
  padding: 8px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.mango_award img {
  width: 100%;
  height: 170px;
  margin-left: -50%;
}

.product_bg_img img {
  width: 100%;
  height: auto;
}

@media (max-width: 414px) {
  .product_bg_img img {
    height: 400px;
  }
  .mango_img img {
    margin-top: -6%;
    margin-bottom: -29%;
    /* height: 30vh; */
  }
  .mango_text h1 {
    font-size: 25px;
  }
  #winner {
    font-size: 15px;
    padding-top: 35px;
  }
  #winner1 {
    font-size: 14px;
    margin-right: 20px;
  }
  #winner {
    margin-right: 20px;
    font-size: 10px;
  }
  #btn_buy_now {
    width: 100%;
  }
  .mango_award img {
    width: 117%;
    margin-top: 20%;
    margin-left: -26%;
  }
}

@media (min-width:416px) and (max-width:600px){
  .mango_img img{
    width: 70%;
    margin-top:18%;
    margin-bottom: -30%;
  }
  .mango_award img{
    width: 70%;
    margin-left: 15%;
  }
}
/* 
* {
  margin:0;
  padding:0;
  box-sizing:border-box;
}
body {
  background-color:#112D4E
} */
#news{
  height: 20px;
  margin: 30px auto;
  background-color: #3F72AF;
  border: 1px solid #DBE2EF;
  border-radius:5px;
  padding:0 5px;
  position: relative;
  overflow:hidden;
}
#par {
  font-family:sans-serif;
  color:#F9F7F7;
  position: absolute;
  
}

h2{color:red;}
#news-bar {
  width: 100%;
  /* background:white; */
  background-color: rgb(255, 138, 0);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
 /* box-shadow: 0 4px 10px black, 0 0 10px black inset; */
  border-radius: 8px;
  font: 21px bold;
  padding:4px;
  padding-left: 20px;
  padding-right: 20px;
}

#news-bar:hover {
  transition: 0.37s;
}

#news-bar a {
  padding:8px 2px;
  color: black;
  text-decoration: none;
}

#news-bar a:hover {
  transition: 0.37s;
  color: red;
}


/* Pop */

@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.hvr-pop:hover,
.hvr-pop:focus,
.hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


/* Float */

.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}


/* slideUp */

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(4%);
  }
  80% {
    transform: translateY(-4%);
  }
  95% {
    transform: translateY(2%)
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}