.header_top_main {
  justify-content: center;
}
.Header_Top_img img {
  width: 120%;
  height: auto;
  margin-top: -30%;
}

.Header_Top_img1 img {
  width: 100%;
  height: auto;
  padding-top: 170px;
}

.header_top_h1 {
  font-family: "Times New Roman", Times, serif;
}
@media (max-width: 414px) {
  #header_m_h1 {
    font-size: 15px;
    margin-top: 4%;
  }
  .Header_Top_img img{
    padding-top: 30%;
  }
}

@media (min-width:540px) and (max-width:670px){
  #header_m_h1{
    font-size: 25px;
  }
}

@media (min-width:417px) and (max-width:541px){
  #header_m_h1{
    font-size: 20px;
  }
}