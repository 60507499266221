.benefits_main{
    justify-content: center;
    gap: 50px;
}
.benefits_text{
    color: white;
}
.grow_img img{
    width: 100%;
    height: 230px;

}